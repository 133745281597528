import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import ProjectPreviewGrid from "../components/project-preview-grid";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from "../lib/helpers";

import { responsiveTitle1 } from "../components/typography.module.css";

export const query = graphql`
  query ArchivePageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      mainImage {
        asset {
          url
        }
      }
    }
    projects: allSanityProjects {
      edges {
        node {
          projects {
            mainImage {
              asset {
                _id
              }
              alt
            }
            _rawExcerpt
            _rawDescription
            _rawAdditionalInfo
            location
            title
            categories {
              title
            }
            _rawPreviewVideo(resolveReferences: {maxDepth: 10})
            slug {
              current
            }
            id
          }
        }
      }
    }
    
  }
`;

const ArchivePage = props => {
  const { data, errors } = props;
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }
  const site = (data || {}).site;
  const projects =
    data && data.projects && mapEdgesToNodes(data.projects);
  const projectNodes = projects[0].projects
  const seoImage = site.mainImage ? `${site.mainImage.asset.url}?w=1200&h=600` : "";
  return (
    <Layout>
      <SEO title="Projects" image={seoImage} />
      <Container>
        <div className="mt-20">
          {projectNodes && projectNodes.length > 0 && <ProjectPreviewGrid nodes={projectNodes} />}
        </div>
      </Container>
    </Layout>
  );
};

export default ArchivePage;
